<template>
  <div class="bgbox" :class="postionVal == 0.1 ? 'hovers' : 'nohovers'">
    
    <div class="bibox" :style="{ right: postionVal + 'rem' }">
      <div class="rLeft">        
        <div class="Putaway" @click="Putaway()">
          <i
            :class="
              postionVal == 0.1 ? 'el-icon-caret-right' : 'el-icon-caret-left'
            "
          ></i>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-item right_item">
          <div class="chart-title">
            <img :src="title" />
            <div class="titleContext titleContext__between">
              <p>安检任务情况</p>
              <div class="titleContext_button">
                <div
                  :class="
                    taskStart == 1 ? 'residents noresidents' : 'residents'
                  "
                  @click="stakClick(1)"
                >
                  居民
                </div>
                <div
                  :class="
                    taskStart == 2 ? 'residents noresidents' : 'residents'
                  "
                  style="margin-left: 0.05rem"
                  @click="stakClick(2)"
                >
                  非居
                </div>
              </div>
            </div>
          </div>
          <el-select
            v-model="taskperiodId"
            :placeholder="taskPlaceholder"
            class="selects"
            @change="getTaskList"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in taskSpinner"
              :key="item.id"
              :label="item.periodName || item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="rightbar pp1" ref="rightbar1">
            <div>
              <div
                ref="polling1"
                class="polling"
                v-for="(item, index) in tableData2"
                :key="index"
                @mouseenter="onmouseover"
                @mouseleave="onmouseout"
              >
                <div class="polling_info">
                  <div class="person_info">
                    <div class="person_name">
                      <div style="width: 0.3rem">{{ item.index + 1 }}</div>
                      <span>{{ item.taskName || item.taskCode }}</span>
                    </div>
                  </div>
                  <div class="person_num">{{ item.perc }}%</div>
                </div>
                <div class="schedule_box_img">
                  <img class="person_img" src="@/assets/br/schedule.png" />
                  <div class="schedule">
                    <div class="schedule_box">
                      <div
                        :class="['schedule_fill', { one: cWidth }]"
                        :style="{ width: item.perc * 0.036 + 'rem' }"
                      ></div>
                      <!-- class="schedule_fill" -->
                      <img
                        :class="['schedule_img', { two: cWidth }]"
                        v-if="item.perc != 0"
                        src="@/assets/br/scheduledot.png"
                        :style="{ left: item.perc * 0.036 - 0.12 + 'rem' }"
                      />
                      <!-- class="schedule_img" -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-item">
          <div class="chart-title">
            <img :src="title" />
            <div class="titleContext titleContext__between">
              <p>安检情况分析</p>
              <div class="titleContext_button">
                <div
                  :class="
                    caseStart == 1 ? 'residents noresidents' : 'residents'
                  "
                  @click="caseClick(1)"
                >
                  居民
                </div>
                <div
                  :class="
                    caseStart == 2 ? 'residents noresidents' : 'residents'
                  "
                  style="margin-left: 0.05rem"
                  @click="caseClick(2)"
                >
                  非居
                </div>
              </div>
            </div>
          </div>
          <!-- <div ref="rightbar2" class="rightbar"></div> -->
          <el-select
            v-model="caseperiodId"
            placeholder="请选择"
            class="selects"
            :popper-append-to-body="false"
            @change="getSecurityAnalysis"
          >
            <el-option
              v-for="item in caseSpinner"
              :key="item.id"
              :label="item.periodName || item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="taskBox">
            <div class="task_box">
              <div class="task_name">用户总数 (户)</div>
              <div class="task_num">{{ caseData.allResidentCount }}</div>
            </div>
            <div class="task_box">
              <div class="task_name">安检户数 (户)</div>
              <div class="task_num">{{ caseData.checkResidentCount }}</div>
            </div>
            <div class="task_box">
              <div class="task_name">安检<br />覆盖率</div>
              <div class="task_num">{{ caseData.coverageRate }}</div>
            </div>
            <div class="task_box">
              <div class="task_name">
                安检<br />
                合格率
              </div>
              <div class="task_num">{{ caseData.passRate }}</div>
            </div>
          </div>
        </div>
        <div class="chart-item">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">用户情况分析</p>
          </div>
          <div ref="rightbar3" class="rightbar"></div>
          <div class="eacher_box">
            <div class="eacher_num">{{eachervalue}}</div>
            <div class="eacher_name">{{name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import title from "@/assets/br/chart-title.png";
import {
  arumPeriodPulldown,
  arumTaskRank,
  aaumUserTypePulldown,
  aaumTaskRank,
  securityAnalysis,
  residentCountChart,
} from "@/RequestPort/bi/index";
export default {
  name: "MapLine",
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    // 任务列表
    pollingList: [],
    tableData2: [],
    // 图片
    title,
    // footer,
    postionVal: 0.1,
    tableData: [{}],
    bar1Data: null,
    rightbar3: null,
    // 任务安检周期
    taskSpinner: [],
    taskPlaceholder: "安检周期",
    taskperiodId: "",
    taskStart: 1,
    // 情况
    caseStart: 1,
    caseperiodId: "",
    caseData: {},
    caseSpinner: [],

    eaches: [],
    Interval: {},
    cWidth: true,
    timer1: null,
    screenWidth: null,
    showTip:true,
    eachervalue:'',
    name:''
  }),

  computed: {},
  watch: {
    "$store.getters.fullState"(val) {
      if (val) {
        this.postionVal = 0.1;
      } else {
        this.postionVal = -4.2;
      }
    },
    "$store.getters.onresize"(val) {
      let that = this;
      setTimeout(() => {
        that.rightbar3.resize();
      }, 500);
    },
    'screenWidth' (val, oldVal) {
        this.rightbar3.resize()
    }
  },
  created() {},
  destroyed() {
    this.timer1 && clearInterval(this.timer1)
    this.stopEcharts();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    // 安检周期下拉
    this.stakClick(1);
    // 获取任务列表
    this.caseClick(1);
    this.rightbar3 = this.$echarts.init(this.$refs.rightbar3);
    this.funMap();
    setTimeout(() => (this.cWidth = false), 1500);
    let that = this
    this.rightbar3.on("mouseover", (params) => {
      let { name, value } = params.data;
      if (params.name) {
        // console.log(params.name)
        that.eachervalue = value
        that.name = name
        // this.rightbar3.setOption({
        //   title: {
        //     text: value,
        //     subtext: name,
        //     textStyle: {
        //       color: "#F0F2F5",
        //       fontSize: 24,
        //     },
        //     subtextStyle: {
        //       fontSize: 14,
        //       fontWeight: "700",
        //       color: "#F0F2F5",
        //     },
        //   },
        // });
      }
    });
  },
  methods: {
    onmouseover() {
     this.timer1 && clearInterval(this.timer1)
    },
    onmouseout() {
        this.setScroll(true);
    },
    // 切换任务情况的居民非居民
    async stakClick(val) {
      this.taskStart = val;
      if (val == 2) {
        this.taskPlaceholder = "用户分类";
        await this.getaAumUserTypePulldown("taskSpinner");
      } else {
        this.taskPlaceholder = "安检周期";
        await this.getArumPeriodPulldown("taskSpinner");
      }
      this.taskSpinner.length > 0 &&
        (this.taskperiodId = this.taskSpinner[0].id);
      await this.getTaskList();
    },
    // 居民安检周期下拉
    async getArumPeriodPulldown(val) {
      let e = await arumPeriodPulldown();
      if (e.code == 200) {
        this[val] = e.data;
      }
    },
    // 非居民用户分类下拉
    async getaAumUserTypePulldown(val) {
      let e = await aaumUserTypePulldown();
      if (e.code == 200) {
        this[val] = e.data;
      }
    },
    // 获取任务列表
    async getTaskList(val) {
      this.timer1 && clearInterval(this.timer1)
      // 居民
      if (this.taskStart == 1) {
        let data = {
          periodId: val,
        }
        await arumTaskRank(data).then((e) => {
          if (e.code == 200) {
            this.pollingList = e.data;
          }
        });
      } else {
        // 非居民
        let data = {
          userTypeId: val,
        }
        await aaumTaskRank(data).then((e) => {
          if (e.code == 200) {
            this.pollingList = e.data;
          }
        });
      }
      this.pollingList.forEach((el, index) => {
        el.index = index;
      });
      await this.setScroll();
    },
    // 情况选择居民非居民
    async caseClick(val) {
      this.caseStart = val;
      if (val == 2) {
        await this.getaAumUserTypePulldown("caseSpinner");
      } else {
        await this.getArumPeriodPulldown("caseSpinner");
      }
      this.caseSpinner.length > 0 &&
        (this.caseperiodId = this.caseSpinner[0].id);
      await this.getSecurityAnalysis(this.caseperiodId);
    },
    // 情况分析数据
    getSecurityAnalysis(val) {
      let data = {
        paramId: val,
        type: this.caseStart,
      }
      securityAnalysis(data).then((e) => {
        if (e.code == 200) {
          this.caseData = e.data;
        }
      });
    },
    backtrack() {
      //  this.$router.go(-1)
      this.$router.push("./index");
    },
    funMap() {
      residentCountChart().then((e) => {
        if (e.code == 200) {
          this.eaches = e.data;
          const color = ["#61D95B", "#FFAC26", "#E92E3B", "#7777F8", "#15D0FF"];
          
          let option = {
            color,
            title: {
              //圆环中间内容
              text: "",
              subtext: "",
              left: "29%",
              top: "40%",
              textAlign: "center",
              textStyle: {
                color: "#F0F2F5",
                fontSize: 16,
                // align: 'center'
              },
              subtextStyle: {
                fontSize: 12,
                fontWeight: "700",
                // align: 'center',
                color: "#F0F2F5",
              },
            },
            legend: {
              type: "scroll",
              orient: "vertical",
              x: "55%",
              y: "center",
              itemWidth: 12,
              itemHeight: 12,
              itemGap: 14,
              textStyle: {
                fontSize: 12,
                color: "#ffffff",
              },
              formatter: function (name) {
                let res = e.data.filter((v) => v.name === name);
                let str = "";
                str = res[0].name + " " + res[0].perc;
                return str;
              },
              //   formatter: function (name) {
              //                 // console.log(data, 'data')
              //                 let total = 0
              //                 let tarValue
              //                 for (let i = 0; i < e.data.length; i++) {
              //                     total += e.data[i].value
              //                     if (e.data[i].name == name) {
              //                     tarValue = e.data[i].value
              //                     }
              //                 }
              //                 //计算出百分比
              //                 let p = ((tarValue / total) * 100).toFixed() + '%'
              //                 return `${name}  ${p}`
              //                 //name是名称，v是数值
              //             }
            },

            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "shadow",
              },
              hideDelay: 100,   
              formatter: "{b} : {c} <br/>{d}%", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
            },
            grid: {
              left: "5%",
              right: "5%",
              bottom: "3%",
              top: "10%",
              containLabel: true,
            },
            series: [
              {
                type: "pie",
                center: ["30%", "50%"],
                radius: ["50%", "53%"],
                data: e.data,
                startAngle: 180,
                label: {
                  show: false,
                },
              },
              {
                type: "pie",
                center: ["30%", "50%"],
                radius: ["50%", "60%"],
                data: e.data,
                startAngle: 180,
                label: {
                  normal: {
                    position: "inner",
                    show: false,
                  },
                },
              },
            ],
          }
          this.stopEcharts()
          this.rightbar3.setOption(option, true);
          this.lunboEcharts(this.rightbar3, option, "PieGraph");
        }
      });
    },

    lunboEcharts(echartsId, dataOption, echartStart) {
      var currentIndex = 0;
      let that = this
      var dataLen = dataOption.series[0].data.length;
     setTimeout(() => {
      this.Interval[Date.now()] = setInterval(() => {
        
        echartsId.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
         // dataIndex: currentIndex //% dataLen,
        });
        // currentIndex = (currentIndex + 1) % dataLen;
          echartsId.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: currentIndex,
          });
          echartsId.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: currentIndex,
            });
            
            
          // this.$nextTick(()=>{
          //   echartsId.dispatchAction({
          //     type: "showTip",
          //     seriesIndex: 0,
          //     dataIndex: currentIndex,
          //   });
          // })
          
        if (
          echartStart == "PieGraph" &&
          !!dataOption.series[0].data[currentIndex].name
        ) {
          if( that.screenWidth > 1200 ) {
            
           this.eachervalue=dataOption.series[0].data[currentIndex].value
           this.name=dataOption.series[0].data[currentIndex].name
          // echartsId.setOption({
          //   title: {
          //     text: dataOption.series[0].data[currentIndex].value,
          //     subtext: dataOption.series[0].data[currentIndex].name,
          //     textStyle: {
          //       color: "#F0F2F5",
          //       fontSize: 24,
          //     },
          //     subtextStyle: {
          //       fontSize: 14,
          //       fontWeight: "700",
          //       color: "#F0F2F5",
          //     },
          //   },
          // });
          }
        }
        currentIndex=currentIndex+1
        if(currentIndex>=dataLen){
              currentIndex=0
            }
      }, 1500);
     },500)
    },
    stopEcharts() {
      Object.values(this.Interval).forEach((k) => clearTimeout(k));
    },
    Putaway() {
      let state = this.$store.getters.fullState;
      this.$store.commit("gis/FullStateChange", !state);
    },
    setScroll(val) {
      if (this.pollingList.length < 6) return (this.tableData2 = this.pollingList);
      !val && (this.tableData2 = [...this.pollingList, ...this.pollingList]);
      let _this = this;
      let body = this.$refs.rightbar1;
      setTimeout(() => {
        // console.log(_this.$refs)
        _this.timer1 && clearInterval(_this.timer1)
        let winHeight = body.clientHeight;
        let max = parseInt(winHeight / 50);
        let rowHeight = parseInt(winHeight / max);
        let els = body.getElementsByClassName("polling");
        for (let el of els) {
          el.style = `height: ${rowHeight}px !important; box-sizing: border-box;`;
        }
        // body.scrollTop = 0;

        _this.timer1 = setInterval(async () => {
          if (_this.active < _this.pollingList.length) {
            _this.sj(rowHeight, body);
          } else {
            body.scrollTop = 0;
            _this.active = 0;
            _this.sj(rowHeight, body);
          }
        }, 1500);
      }, 200);
    },
    async sj(rowHeight, body) {
      let _this = this;
      if (this.active < this.pollingList.length) {
        this.active += 1;
        for (let i = 0; i <= rowHeight; i++) {
          await _this.wait(0.5 / rowHeight);
          body.scrollTop = Math.ceil(body.scrollTop + 1);
          if (body.scrollTop >= this.active * rowHeight) {
            body.scrollTop = parseInt(this.active * rowHeight);
            break;
          }
        }
      } else {
        body.scrollTop = 0;
        this.active = 0;
      }
    },
    wait(time = 0) {
      return new Promise((res, rej) => setTimeout(() => res(), time * 1000));
    },
  },
};
</script>

<style lang="scss" scoped>

.rightbar {
  flex: 1;
  width: 100%;
}
.bgbox {
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 4.8rem;
  right: 0;
  background: linear-gradient(-90deg, #05193d 60%, rgba(5, 25, 61, 0) 100%);
}
.hovers {
  background: linear-gradient(-90deg, #05193d 60%, rgba(5, 25, 61, 0) 100%);
}
.nohovers {
  width: 0.1rem;
  background: none;
}
.bibox {
  transition: right 0.5s;
  position: fixed;
  z-index: 9;
  top: 57px;
  right: 0;
  height: calc(100vh - 74px);
  text-align: center;
  color: var(--title-text-color);
  display: flex;
  justify-content: flex-end;
}
.chart-box {
  width: 4.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-item {
  width: 100%;
  height: 28%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(8, 65, 100, 0.4),
    rgba(27, 46, 65, 0.4)
  );
  .chart-title {
    color: #fff;
    text-align: left;
    position: relative;
    height: 35px;
    p,
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    p {
      padding-left: 0.4rem;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-style: italic;
      // line-height: 0.35rem;
      line-height: 35px;
      text-shadow: 0px 3px 0px rgba(0, 9, 17, 0.52);
    }
  }
  .chart-footer {
    width: 100%;
    overflow: hidden;
  }
}
.right_item {
  height: 42%;
}
.rLeft {
  position: relative;
}
.Putaway {
  background: #182c3e;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.46rem;
}
.polling {
  padding: 0.1rem 0.2rem;
  height: 0.39rem;
  .polling_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .person_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      .person_name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00f6ff;

        text-align: left;
        span {
          display: block;
          overflow: hidden; //超出隐藏
          white-space: nowrap; //不折行
          text-overflow: ellipsis;
          width: 2.8rem;
          font-size: 0.16rem;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .person_num {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .schedule_box_img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .person_img {
      width: 0.09rem;
      height: 0.1rem;
      margin-right: 0.13rem;
    }
  }
  .schedule {
    flex: 1;
    height: 0.16rem;
    line-height: 0.16rem;
    border: 1px solid rgba(223, 254, 255, 0.15);
    .schedule_box {
      width: 3.62rem;
      height: 0.04rem;
      background: rgba(94, 167, 255, 0.3);
      border-radius: 0.02rem;
      margin: 0.06rem auto;
      position: relative;
      .schedule_img {
        position: absolute;
        top: -0.06rem;
        width: 0.2rem;
        height: 0.16rem;
        transition-duration: 1s;
      }
    }
    .schedule_fill {
      width: 1rem;
      height: 0.04rem;
      background: linear-gradient(
        90deg,
        rgba(0, 246, 255, 0.85) 80%,#6EF2F9, #c5fdff 
      );
      transition-duration: 1s;
      border-radius: 2px;
    }
    .one {
      width: 0rem !important;
    }
    .two {
      left: -0.1rem !important;
    }
  }
}
.pp1 {
  overflow-y: auto;
  height: 2.9rem;
}
::-webkit-scrollbar {
  width: 0px;
}
.titleContext__between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleContext_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    // top: 0.04rem;
    right: 0.1rem;
    top: 50%;
    margin-top: -0.15rem;
    .residents {
      width: 0.56rem;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      background: url("../../../assets/br/resident.png") no-repeat;
      background-size: 100% 100%;
      font-size: 0.14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #63b3ff;
      cursor: pointer;
    }
    .noresidents {
      color: #ffffff;
    }
  }
}
.selects {
  width: 3.86rem;
  height: 0.2rem;
  border: 1px solid rgba(14, 139, 255, 0.5);
  margin: 0.13rem auto;
}
::v-deep .el-select,
::v-deep .el-input,
::v-deep .el-input__inner {
  background-color: rgba(0, 81, 157, 0.1);
  color: rgba(255, 255, 255, 1);
  border: none; // 去掉边框
  // border-color: #XXXXXX // 默认边框的颜色
  text-align: left;
  border-radius: 4px;
  line-height: 0.2rem;
}
::v-deep .el-input__inner {
  width: 3.86rem;
  height: 0.34rem;

  border: 1px solid rgba(14, 139, 255, 0.5);
}
::v-deep .el-input__icon {
  line-height: 0.34rem;
}
::v-deep .el-input__inner:hover {
  border: 1px solid rgba(14, 139, 255, 0.5);
}
::v-deep .el-select-dropdown {
  background: #0a2f5a;
  // border:0px;
  border-top: 3px;
  border: 1px solid rgba(14, 139, 255, 0.5);
  // top: 125px !important;
  // border-radius: 0px;
}
::v-deep .el-select-dropdown__item {
  background-color: rgba(0, 81, 157, 0.1);

  color: #fff;
}
::v-deep .el-popper {
  margin-top: 4px;
}
::v-deep .el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none;
  // border-bottom-color:rgba(0, 81, 157, 0.1) !important;
}
::v-deep .el-select-dropdown__item.hover,
::v-deep .el-select-dropdown__item:hover {
  // color:rgb(21,43,94);
  background: #042953;
}
.taskBox {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  width: 3.86rem;
  margin: auto;
  .task_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url("../../../assets/br/taskimg.png") no-repeat;
    background-size: 100% 100%;
    width: 1.67rem;
    height: 0.43rem;
    padding: 0.2rem 0.13rem;
    .task_name {
      // width: 0.56rem;
      font-size: 0.14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #309cff;
      text-align: left;
    }
    .task_num {
      font-size: 0.24rem;
      // flex: 1;
      // margin-left: 0.1rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.eacher_box{
  position: absolute;
  top: 48%;
    left: 18%;
    text-align: center;
    min-width: 1rem;
  .eacher_num{
    color: #F0F2F5;
    font-size: 0.24rem;
  }
  .eacher_name{
    font-size: 0.14rem;
    font-weight: 700;
    color: #F0F2F5
  }
}
</style>
